export const getStopsRoutes = (store) =>
  store && store.stops && store.stops.result && store.stops.result.routes;

export const getStopsResultOpId = (store) =>
  store && store.stops && store.stops.result && store.stops.result.opId;

export const getServiceRoutes = (store) =>
  store && store.service && store.service.result && store.service.result.routes;

export const getServiceError = (store) =>
  store && store.service && store.service.result && store.service.result.errorMessage;

export const getPolylines = (store) =>
  store && store.polyline && store.polyline.result && store.polyline.result.polylines;

export const getMapStops = (store) =>
  store && store.polyline && store.polyline.result && store.polyline.result.stops;

export const getMasterListId = (routes) =>
  routes && routes[0] && routes[0]["masters"].length && (routes[0]["masters"][0]["grMasters"] ? routes[0]["masters"][0]["grMasters"] : routes[0]["masters"]);

export const getStops = (routes) =>
  routes && routes[0] && routes[0]["masters"].length && routes[0]["masters"][0]["stops"];

export const getServices = (routes) =>
  routes && routes[0] && routes[0]["masters"].length && routes[0]["masters"][0]["services"];

export const getVia = (routes) =>
  routes && routes[0] && routes[0]["masters"].length && routes[0]["masters"][0]["via"];

export const getTimetableChanges = (routes) =>
  routes && routes[0] && routes[0]["dowChanges"];

export const getOpId = (store) =>
  store && store.service && store.service.result && store.service.result.opId;
