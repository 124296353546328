import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";

import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "leaflet/dist/leaflet.css";

import { Provider } from "react-redux";
import store from "./redux/store";

import ReactGA from "react-ga";
import TagManager from 'react-gtm-module'

ReactGA.initialize("UA-2380865-8");
ReactGA.pageview(window.location.pathname + window.location.search);

const tagManagerArgs = {
  gtmId: 'GTM-NL2W46L'
}

TagManager.initialize(tagManagerArgs)

const rootElement = document.getElementById("root");
ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
  rootElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
