import { RESET_VIEW } from "../actionTypes";

export default function (state = { reset: false }, action) {
  switch (action.type) {
    case RESET_VIEW:
      return {
        reset: action.payload,
      };

    default:
      return state;
  }
}
