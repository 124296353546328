import React from "react";
import { Header } from "./components/Header";
import { MainContainer } from "./components/MainContainer";
import { MapContainer } from "./components/MapContainer";
import NotFound from "./components/NotFound";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import "./App.scss";

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/map/:privateCode/:date?">
            <Header />
            <MapContainer />
          </Route>
          <Route path="/:privateCode/:date?">
            <Header />
            <MainContainer />
          </Route>
          <Route component={NotFound} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
