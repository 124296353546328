import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Switch from "react-switch";
import { Map, Marker, Popup, Polyline } from "react-leaflet";
import L from "leaflet";
import polyUtil from "polyline-encoded";
import { v4 } from "uuid";
import smoothscroll from "smoothscroll-polyfill";
import moment from "moment";
import { fetchPolyline } from "../redux/actions";

import greenIcon from "../assets/images/stop-start.svg";
import redIcon from "../assets/images/stop-finish.svg";
import yellowIcon from "../assets/images/stop.svg";
import blueIcon from "../assets/images/stop-blue.svg";

import 'mapbox-gl-leaflet';
import 'mapbox-gl';

import {
  getStopsRoutes,
  getServices,
  getTimetableChanges,
  getStopsResultOpId,
  getPolylines,
  getMapStops,
  getMasterListId,
  getServiceRoutes,
  getServiceError
} from "../redux/selectors";
import { StopsTable } from "./StopsTable";
import { getStopName } from "../helpers/get-stop-name";

L.Icon.Default.imagePath =
  "//cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/";

smoothscroll.polyfill();

const MAP_ICON_BIG = {
  iconSize: [24, 40],
  format: "svg",
  iconAnchor: [12, 40],
  popupAnchor: [1, -40],
};

const MAP_ICON = {
  iconSize: [14, 26],
  format: "svg",
  iconAnchor: [7, 26],
  popupAnchor: [0, -26],
};

class MapWithProvider extends Map {
  createLeafletElement(props) { 
    const LeafletMapElement = super.createLeafletElement(props);

    L.mapboxGL({
      attribution:
        '<a href="https://www.maptiler.com/copyright/" target="_blank">© MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">© OpenStreetMap contributors</a>',
      accessToken: 'pk.eyJ1IjoidXRyYWNrbWFwIiwiYSI6Im9iV245NnMifQ.ddr0eLkRuZUoNVglI8tZvg',
      style: 'https://api.maptiler.com/maps/basic/style.json?key=qFLLNZGl77ciGk4TxKUS'
    }).addTo(LeafletMapElement);

    return LeafletMapElement;
  }
}

export const createIcon = ({
  name,
  iconSize,
  format,
  iconAnchor,
  popupAnchor,
}) =>
  new L.Icon({
    iconUrl: require(`../assets/images/${name}.${format}`),
    iconRetinaUrl: require(`../assets/images/${name}.${format}`),
    iconSize,
    iconAnchor,
    popupAnchor,
  });

export const MainContainer = () => {
  const dispatch = useDispatch();
  const {
    services,
    polyline,
    mapStops,
    reset,
    masterListId,
    rawroutes,
    errorMessage
  } = useSelector((state) => ({
    services: getServices(getStopsRoutes(state)),
    masterListId: getMasterListId(getStopsRoutes(state)),
    timetableChanges: getTimetableChanges(getStopsRoutes(state)),
    stopsOpId: getStopsResultOpId(state),
    polyline: getPolylines(state),
    mapStops: getMapStops(state),
    rawroutes: getServiceRoutes(state),
    errorMessage: getServiceError(state),
    reset: state.view.reset,
  }));

  const [showAllStops, setShowAllStops] = useState(false);
  const [currentMasterListId, setCurrentMasterListId] = useState([]);
  const [showMap, setShowMap] = useState(false);
  const [markers, setMarkers] = useState([]);
  const [bounds, setBounds] = useState();
  const [primaryService, setPrimaryService] = useState(null);

  const { opId, privateCode, date } = useParams();
  useEffect(() => {
    if (errorMessage) {
      setTimeout(() => {
        window.parent.postMessage({
          frameHeight: window.screen.width < 1100 ? 600 : 460
        }, '*');
      }, 100);
    }
    if (reset) {
      setShowMap(false);
      setShowAllStops(false);
    }

    if ((!polyline || currentMasterListId !== masterListId) && masterListId && masterListId.length) {
      setCurrentMasterListId(masterListId);
      let primarymasterListId = [];
      if (privateCode) {
        const codeParts = privateCode.split('_');
        if (codeParts.length > 1) {
          primarymasterListId = masterListId.filter(masterId => masterId.privateCode === codeParts[0])
          setPrimaryService(primarymasterListId.length ? primarymasterListId[0]["service"] : masterListId[0]["service"]);
        }
      }
      dispatch(
        fetchPolyline(
          JSON.stringify({
            departDate: date ? date : moment().format("YYYY-MM-DD"),
            masterListId: primarymasterListId.length ? primarymasterListId[0]["masterListId"] : masterListId[0]["masterListId"],
            masterStops: true,
            reqId: 0
          })
        )
      );
    }

    if (mapStops && mapStops.length) {
      const stopsLeng = mapStops.length;

      setMarkers(
        mapStops && mapStops.length && mapStops.map((stop, index) => {
          if (index === 0) {
            return {
              ...stop,
              google: `https://www.google.com/maps/place/${stop.loc.lat},${stop.loc.lon}`,
              icon: createIcon({
                name: "stop-start",
                ...MAP_ICON_BIG,
              }),
              type: "origin",
            };
          }

          if (index + 1 === stopsLeng) {
            return {
              ...stop,
              google: `https://www.google.com/maps/place/${stop.loc.lat},${stop.loc.lon}`,
              icon: createIcon({
                name: "stop-finish",
                ...MAP_ICON_BIG,
              }),
              type: "dest",
            };
          }

          return {
            ...stop,
            google: `https://www.google.com/maps/place/${stop.loc.lat},${stop.loc.lon}`,
            icon: createIcon({
              name: stop.forAllJrn ? "stop" : "stop-blue",
              ...MAP_ICON,
            }),
            type: stop.forAllJrn ? "stop" : "stop-blue",
          };
        })
      );

      if (showMap) {
        setBounds(mapStops.map((stop, index) => {
          if (stop.polyline)
            return polyUtil.decode(stop.polyline);
          return {
            lat: stop.loc.lat,
            lng: stop.loc.lon,
          };
        }));
      }
    }
  }, [reset, showMap, polyline, date, dispatch, masterListId, mapStops, privateCode, currentMasterListId, errorMessage]);

  return errorMessage ?
  <div className="no-timetable-header">
    <div className={`no-timetable no-timetable-${opId ? opId : rawroutes.length ? rawroutes[0].opId : ''}`}>{errorMessage}</div>
  </div>
  : services && services.length && rawroutes && rawroutes.length ? (
    <Fragment>
      <div className={`main-container main-container-${opId ? opId : rawroutes[0].opId}`}>
        <div className="switcher">
          <Switch
            checked={showAllStops}
            onChange={setShowAllStops}
            onColor="#10bd59"
            onHandleColor="#D6D6D6"
            handleDiameter={25}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            height={25}
            width={46}
            className="react-switch"
            id="material-switch"
          />
          <span className="see-stops">See all stops</span>
        </div>
        <div className="switcher">
          <span className="see-map">See stops on a map</span>
          <span className="see-map see-map--mobile">See map</span>
          <Switch
            checked={showMap}
            onChange={setShowMap}
            onColor="#10bd59"
            onHandleColor="#D6D6D6"
            handleDiameter={25}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            height={25}
            width={46}
            className="react-switch"
            id="material-switch"
          />
        </div>
      </div>
      {showMap && mapStops && mapStops.length && (
        <div className="map">
          {primaryService && 
            <div className={`mapService mapService--${rawroutes[0].opId}`}>{primaryService}</div>
          }
          <MapWithProvider bounds={bounds ? bounds: null} boundsOptions={{padding: [5, 15]}}>

            {polyline && polyline.length && polyline.map((encoded) => {
              return <Polyline key={v4()}
                positions={polyUtil.decode(encoded)}
                color="#8E8E8E"
                weight={7}
                opacity={0.6}
              />
            })}

            {markers.length &&
              markers.map(({ loc, icon, fName, type, google }) => (
                <Marker key={v4()} position={loc} icon={icon}>
                  <Popup>
                    {getStopName(fName).length > 1 ? (
                      <Fragment>
                        <h3 className={type}>{getStopName(fName)[0]}</h3>
                        <h4>{getStopName(fName)[1]}</h4>
                      </Fragment>
                    ) : (
                      <h3 className={type}>{fName}</h3>
                    )}
                    <br />
                    <a
                      className={`link-${opId ? opId : rawroutes[0].opId}`}
                      href={google}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Directions to Stop
                    </a>
                  </Popup>
                </Marker>
              ))}
          </MapWithProvider>
          <div className="legend">
            <div className="label">Key</div>
            <div className="section section--first">
              <div className="item"><img alt='startStop' width='14px' src={greenIcon} /> First stop</div>
              <div className="item"><img alt='endStop' width='14px' src={redIcon} /> Last stop</div>
            </div>
            <div className="section">
              <div className="item"><img alt='yellowStop' width='14px' src={yellowIcon} /> Stop served on all trips</div>
              <div className="item"><img alt='blueStop' width='14px' src={blueIcon} /> Stop served on some trips</div>
            </div>
          </div>
        </div>
      )}
      <StopsTable showAllStops={showAllStops} />
    </Fragment>
  ) : (
    <div>
    </div>
  );
};
