export const getStopName = (str, mobileView = false) => {
  if (str) {
    // eslint-disable-next-line
    let arr = [];
    let positions = [];

    if (str.indexOf(":") !== -1) {
      arr = [
        str.substring(0, str.indexOf(":") + 1).replace(":", ""),
        str
          .substring(str.indexOf(":") + 1)
          .replace(":", "")
          .replace(",", " ")
          .replace(/\s*\[.*?\]\s*/g, ""),
      ];
      positions = [];

      if (mobileView && arr[0].length > 20) {
        for (let i = 0; i < arr[0].length; i++) {
          if (arr[0][i].match(/[a-z]/) != null) {
            positions.push(i);
          }
        }

        const index = positions[0] - 1;

        arr = [arr[0].substring(0, index), arr[0].substring(index), arr[1]];
      }

      return arr;
    }

    if (str.indexOf(",") !== -1) {
      arr = [
        str.substring(0, str.indexOf(",") + 1).replace(",", ""),
        str.substring(str.indexOf(",") + 1).replace(",", ""),
      ];
      positions = [];

      if (mobileView && arr[0].length > 20) {
        for (let i = 0; i < arr[0].length; i++) {
          if (arr[0][i].match(/[a-z]/) != null) {
            positions.push(i);
          }
        }

        const index = positions[0] - 1;

        arr = [arr[0].substring(0, index), arr[0].substring(index), arr[1]];
      }

      return arr;
    }

    if (str.indexOf("(") !== -1 && str.length > 20) {
      return (arr = [
        str.substring(0, str.indexOf("(") - 1),
        str.substring(str.indexOf("(") - 1),
      ]);
    }

    return [str];
  }

  return ["Unknow"];
};
