import React, { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import { v4 } from "uuid";
import $ from "jquery";

import ReactGA from 'react-ga';

import { getStops, getServices, getStopsRoutes, getServiceRoutes } from "../redux/selectors";
import { getStopName } from "../helpers/get-stop-name";
import collapseIcon from "../assets/images/icons/collapse.svg";
import collapseIconCV from "../assets/images/icons/CV/collapse.svg";

const removeDuplicateIndex = (arr, value) => {
  let i = 0;
  while (i < arr.length) {
    if (arr[i] === value) {
      arr.splice(i, 1);
    } else {
      ++i;
    }
  }
  return arr;
};

const getCollapseIcon = (opId) => {
  switch (opId) {
    case "CV":
      return collapseIconCV;
    default:
      return collapseIcon;
  }
};

let height = null;
const sendPostMessage = () => {
  if (height !== document.getElementById('root').offsetHeight) {
    height = document.getElementById('root').offsetHeight;
    window.parent.postMessage({
      frameHeight: height > 460 ? height: 460
    }, '*');
  }
}

function checkForScrollEdges() {
  if (
    $(".stops-table-scrollable:visible")[0] &&
    $(".stops-table-scrollable:visible")[0].scrollLeft === 0
  ) {
    $("button.button-scroll").attr("disabled", true);
    if (
      $(".stops-table:visible:first tr:first td:last").position() &&
      $(".stops-table:visible:first tr:first td:last").position().left <
        $(".stops-table-scrollable:visible:first").width() +
          $(".stops-table-scrollable:visible:first").position().left
    ) {
      $("button.button-scroll-later").attr("disabled", true);
      $("button.button-scroll-later, button.button-scroll").hide();
      $(".stops-table-scrollable:visible:first").css("overflow-x", "hidden");
    } else {
      $(".stops-table-scrollable:visible:first").css("overflow-x", "scroll");
      $("button.button-scroll-later, button.button-scroll").show();
    }
  } else {
    if (
      !(
        $(".stops-table:visible:first tr:first td:last").position() &&
        $(".stops-table:visible:first tr:first td:last").position().left <
          $(".stops-table-scrollable:visible:first").width() +
            $(".stops-table-scrollable:visible:first").position().left
      )
    ) {
      $("button.button-scroll").attr("disabled", false);
      $("button.button-scroll-later, button.button-scroll").show();
      $(".stops-table-scrollable:visible:first").css("overflow-x", "scroll");
    }
    if (
      $(".stops-table-scrollable:visible")[0] &&
      $(".stops-table-scrollable:visible")[0].offsetWidth +
        $(".stops-table-scrollable:visible")[0].scrollLeft >=
        $(".stops-table-scrollable:visible")[0].scrollWidth
    ) {
      $("button.button-scroll-later").attr("disabled", true);
    } else {
      $("button.button-scroll-later").attr("disabled", false);
    }
  }
}

export const StopsTable = (props) => {
  const displayNone = { display: "none" };
  const { stops, services, rawroutes } = useSelector((state) => ({
    stops: getStops(getStopsRoutes(state)),
    services: getServices(getStopsRoutes(state)),
    rawroutes: getServiceRoutes(state)
  }));

  const [showLegend, setShowLegend] = useState(false);
  const [routes, setRoutes] = useState([]);
  const [servicesRoutes, setServicesRoutes] = useState([]);
  const [indexes, setIndex] = useState([]);
  const { privateCode, date } = useParams();

  const exportPDF = () => {
    let pdfroute = rawroutes[0];
    for (var i=0; i < rawroutes.length; i++) {
      if (rawroutes[i].origin === routes[0].fName)
        pdfroute = rawroutes[i];
    }

    ReactGA.event({
      category: 'pdf',
      action: 'daily-download',
      label: privateCode ? privateCode : pdfroute.privateCode,
      value: 1
    });
    //_gaq.push(['_trackEvent', 'pdf', 'daily-download', privateCode ? privateCode : pdfroute.privateCode, '0']);
    window.open(
        "https://bustimetables-pdf.utrackapps.com/generateDaily.php?origin=" +
        pdfroute.origin +
        "&dest=" +
        pdfroute.dest +
        "&privateCode=" +
        (privateCode ? privateCode : pdfroute.privateCode) +
        "&departDate=" +
        (date ? moment(date).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD")),
      "_blank"
    );
  };

  useEffect(() => {
    if (stops && stops.length) {
      for (const [index, stop] of stops.entries()) {
        if (index && stop.layover)
          setShowLegend(true);
      }
    }
    if (props.showAllStops) {
      if (stops && stops.length) {
        setIndex(
          stops
            .map((stop, index) => (stop.tp || index === stops.length ? index : null))
            .filter((stop) => stop !== null)
        );
        setRoutes(stops.map((route) => ({ ...route, show: true })));
      }

      if (services && services.length) {
        setServicesRoutes(
          services.map(({ stops }) =>
            stops.map((stop) => ({
              ...stop,
              show: true,
            }))
          )
        );
      }
    } else {
      if (stops && stops.length) {
        setIndex([]);
        setRoutes(stops.map((route) => ({ ...route, show: !!route.tp })));
      }

      if (services && services.length) {
        $(".stops-table-scrollable:visible:eq(0)").on("scroll", function () {
          checkForScrollEdges();
        });

        $(".scroll-buttons button.button-scroll")
          .on("touchstart mousedown", function () {
            // start scrolling left
            $(".stops-table-scrollable:visible:eq(0)").stop();
            checkForScrollEdges();
            $(".stops-table-scrollable:visible:eq(0)").animate(
              { scrollLeft: 0 },
              $(".stops-table-scrollable:visible")[0].scrollLeft,
              "linear"
            );
          })
          .on("touchend mouseup", function () {
            // cancel the scroll
            $(".stops-table-scrollable:visible:eq(0)").stop();
            checkForScrollEdges();
          });

        $(".scroll-buttons button.button-scroll-later")
          .on("touchstart mousedown", function () {
            // start scrolling right
            $(".stops-table-scrollable:visible:eq(0)").stop();
            checkForScrollEdges();
            $(".stops-table-scrollable:eq(0)").animate(
              {
                scrollLeft: $(".stops-table-scrollable:visible")[0].scrollWidth,
              },
              $(".stops-table-scrollable:visible")[0].scrollWidth -
                $(".stops-table-scrollable:visible")[0].scrollLeft,
              "linear"
            );
          })
          .on("touchend mouseup", function () {
            // cancel the scroll
            $(".stops-table-scrollable:visible:eq(0)").stop();
            checkForScrollEdges();
          });

        setServicesRoutes(
          services.map(({ stops }) => {
            return stops.map((stop) => {
              if (stop) {
                return {
                  show: true,
                  ...stop,
                };
              } else {
                return {
                  show: false,
                };
              }
            });
          })
        );
      }
    }
    $(function () {
      setTimeout(() => {
        checkForScrollEdges();
        sendPostMessage();
        if (document.querySelector("td.next")) {
          checkForScrollEdges();
          let offset =
            document.querySelector("td.next").offsetLeft -
            document.querySelector(".stops-table-pinned").offsetWidth;
            offset = offset + 12;
            document.querySelector(".stops-table-scrollable").scrollLeft = offset;
        }
      }, 100);
    });
    $(window).resize(function () {
      checkForScrollEdges();
      sendPostMessage();
    });
  }, [stops, services, props]);

  const handleStopCollapse = (index) => {
    setIndex([...indexes, index]);

    let indexLast;
    for (var i = 0; i < routes.length; ++i) {
      if (i > index && routes[i].tp) {
        indexLast = i;
        break;
      }
    }

    if (!indexLast)
      indexLast = routes.length;
    if (!indexes.includes(index)) {
      setRoutes(
        routes.map((route, i) => {
          if (i > index && !route.tp && indexLast > i) {
            return {
              ...route,
              show: true,
            };
          }

          return route;
        })
      );

      setServicesRoutes(
        servicesRoutes.map((stops) => {
          return stops.map((route, i) => {
            if (i > index && !route.tp && indexLast > i) {
              return {
                ...route,
                show: true,
              };
            }

            return route;
          });
        })
      );
    } else {
      setRoutes(
        routes.map((route, i) => {
          if (i > index && !route.tp && indexLast > i) {
            return {
              ...route,
              show: false,
            };
          }

          return route;
        })
      );

      setServicesRoutes(
        servicesRoutes.map((stops) => {
          return stops.map((route, i) => {
            if (i > index && !route.tp && indexLast > i) {
              return {
                ...route,
                show: false,
              };
            }

            return route;
          });
        })
      );

      setIndex(removeDuplicateIndex(indexes, index));
    }

    $(function () {
      setTimeout(() => {
        sendPostMessage();
      }, 1000);
    });
  };

  const getStopsDotStyles = (index, stop) => {
    if (index === 0) {
      return "stops-tracking-dot";
    }

    if (index === stops.length - 1) {
      return "stops-tracking-dot stops-tracking-dot--finish";
    }

    if (stop && stop.tp) {
      return "stops-tracking-dot stops-tracking-dot--between stops-tracking-dot--tp";
    }

    return "stops-tracking-dot stops-tracking-dot--between";
  };

  return (
    <Fragment>
      {services && services.length && (
        <Fragment>
          <div className={`stops-table-header stops-table-header-${rawroutes[0].opId}`}>
            <div className="stops-table-header__stops">
              <div className={`wrapper-download-pdf wrapper-download-pdf-${rawroutes[0].opId}`}>
                <button onClick={() => exportPDF()} className="button-download-pdf">
                  {moment(date).format("Do MMM")} PDF
                </button>
              </div>
            </div>
            <div className="stops-table-header__times">
              <span>Times</span>
              <div className="scroll-buttons">
                <button
                  type="button"
                  className="button-scroll noselect"
                  style={{ display: "none" }}
                >
                  Earlier
                </button>
                <button
                  type="button"
                  className="button-scroll-later noselect"
                  style={{ display: "none" }}
                >
                  Later
                </button>
              </div>
            </div>
          </div>
          <div className="stops-table-holder">
            <div className="stops-table-scrollable">
              <table className="stops-table">
                <tbody>
                {(rawroutes.length && rawroutes[0].service && rawroutes[0].service.includes('_'))
                  && (<tr key={v4()} className="service">
                    <td></td>
                    <td></td>
                    <td></td>
                    {services.map((serviceInfo) => 
                      <td key={v4()}>{serviceInfo.serviceName}</td>)}
                    </tr>
                  )}

                  {routes && routes.length
                    ? routes.map((stop, index) =>
                        stop.show || index === 0 ? (
                          <tr
                            key={v4()}
                            className={stop.tp ? "timing-point" : "point"}
                          >
                            <td>
                              <div className="stops-tracking">
                                <span
                                  className={getStopsDotStyles(index, stop)}
                                ></span>
                              </div>
                            </td>
                            <td>
                              {getStopName(stop.fName).length > 1 ? (
                                <Fragment>
                                  {getStopName(stop.fName)[0]} <br />
                                  <span className="subTitle">
                                    {getStopName(stop.fName)[1]}
                                  </span>
                                </Fragment>
                              ) : (
                                stop.fName
                              )}
                            </td>
                            <td>
                              {stop.tpGroup ? (
                                <img
                                  onClick={() => {
                                    handleStopCollapse(index);
                                  }}
                                  className={
                                    !indexes.includes(index)
                                      ? "stops-collapse"
                                      : "stops-collapse stops-collapse--down"
                                  }
                                  src={getCollapseIcon(rawroutes[0].opId)}
                                  alt="collapse icon"
                                />
                              ) : null}
                            </td>

                            {servicesRoutes &&
                              servicesRoutes.length &&
                              servicesRoutes.map((stops) => {
                                return stops[index].show || index === 0 ? (
                                  <td
                                    key={v4()}
                                    className={`
                                      ${servicesRoutes.length === 1 ? 'time--single' : ''}
                                      ${(index === 0 &&
                                      moment(stops[0]["depart"]).isAfter(
                                        moment()
                                      )) || ((!stops[0]["depart"]) && moment(stops[1]["depart"]).isAfter(
                                        moment()
                                      ))
                                        ? !stops[index]["depart"] ? "next time center" : "next time"
                                        : !stops[index]["depart"] ? "time center" : "time"}
                                    `}
                                  >
                                    {!stops[index]["depart"] ? (
                                      <div className="no-time">
                                        -
                                      </div>
                                    ) : (stops[index]["arrive"] && stops[index]["type"] === 2) ? (
                                      <Fragment>
                                        {moment(stops[index]["arrive"]).format(
                                          "HH"
                                        )}
                                        <span className="time_colon">:</span>
                                        {moment(stops[index]["arrive"]).format(
                                          "mm"
                                        )}
                                      </Fragment>
                                    ) : stops[index]["arrive"] ===
                                      stops[index]["depart"] ? (
                                      <Fragment>
                                        {moment(stops[index]["depart"]).format(
                                          "HH"
                                        )}
                                        <span className="time_colon">:</span>
                                        {moment(stops[index]["depart"]).format(
                                          "mm"
                                        )}
                                      </Fragment>
                                    ) : (
                                      <Fragment>
                                        {stops[index]["layover"] ? 
                                          <span className="layover">(a)</span>
                                          :
                                          <span className="layover"></span>
                                        }
                                        {moment(stops[index]["arrive"]).format(
                                          "HH"
                                        )}
                                        <span className="time_colon">:</span>
                                        {moment(stops[index]["arrive"]).format(
                                          "mm"
                                        )}
                                        <br />
                                        {stops[index]["layover"] ? 
                                          <span className="layover">(d)</span>
                                          :
                                          <span className="layover"></span>
                                        }
                                        {moment(stops[index]["depart"]).format(
                                          "HH"
                                        )}
                                        <span className="time_colon">:</span>
                                        {moment(stops[index]["depart"]).format(
                                          "mm"
                                        )}
                                      </Fragment>
                                    )}
                                  </td>
                                ) : (
                                  <td key={v4()} className='center time'>
                                    <div className="no-time">
                                      -
                                    </div>
                                  </td>
                                );
                              })}
                          </tr>
                        ) : null
                      )
                    : null}
                </tbody>
              </table>
            </div>
            <div className="stops-table-pinned">
              <table className="stops-table">
                <tbody>
                {((rawroutes.length && rawroutes[0].service && rawroutes[0].service.includes('_')))
                  && (<tr key={v4()} className="service">
                    <td></td>
                    <td></td>
                    <td></td>
                    {services.map((serviceInfo) => 
                      <td key={v4()} style={displayNone}>{serviceInfo.serviceName}</td>)}
                    </tr>
                  )}

                  {routes && routes.length
                    ? routes.map((stop, index) =>
                        stop.show || index === 0 ? (
                          <tr
                            key={v4()}
                            className={stop.tp ? "timing-point" : "point"}
                          >
                            <td>
                              <div className="stops-tracking">
                                <span
                                  className={getStopsDotStyles(index, stop)}
                                ></span>
                              </div>
                            </td>
                            <td>
                              {getStopName(stop.fName).length > 1 ? (
                                <Fragment>
                                  {getStopName(stop.fName)[0]} <br />
                                  <span className="subTitle">
                                    {getStopName(stop.fName)[1]}
                                  </span>
                                </Fragment>
                              ) : (
                                stop.fName
                              )}
                            </td>
                            <td>
                              {stop.tpGroup ? (
                                <img
                                  onClick={() => {
                                    handleStopCollapse(index);
                                  }}
                                  className={
                                    !indexes.includes(index)
                                      ? "stops-collapse"
                                      : "stops-collapse stops-collapse--down"
                                  }
                                  src={getCollapseIcon(rawroutes[0].opId)}
                                  alt="collapse icon"
                                />
                              ) : null}
                            </td>

                            {servicesRoutes &&
                              servicesRoutes.length &&
                              servicesRoutes.map(
                                (stops) =>
                                  stops[index].show && (
                                    <td key={v4()} style={displayNone} className={`time ${servicesRoutes.length === 1 ? 'time--single' : ''}`}>
                                      {stops[index]["layover"] ?
                                        <span className="layover">(a)</span>
                                        :
                                        <span className="layover"></span>
                                      }
                                      {moment(stops[index]["arrive"]).format(
                                        "HH"
                                      )}
                                      <span className="time_colon">:</span>
                                      {moment(stops[index]["arrive"]).format(
                                        "mm"
                                      )}
                                      <br />
                                      {stops[index]["layover"] ? 
                                          <span className="layover">(d)</span>
                                          :
                                          <span className="layover"></span>
                                      }
                                      {moment(stops[index]["depart"]).format(
                                        "HH"
                                      )}
                                      <span className="time_colon">:</span>
                                      {moment(stops[index]["depart"]).format(
                                        "mm"
                                      )}

                                    </td>
                                  )
                              )}
                          </tr>
                        ) : null
                      )
                    : null}
                </tbody>
              </table>
            </div>
          </div> 
          <div className="legend">
            <div className="label">Key</div>
            {showLegend && <div className="item"><span className="time">(a)</span> arrival time at the stop</div>}
            {showLegend && <div className="item"><span className="time">(d)</span> departure time at the stop</div>}
            <div className="item italic">If you click on 'see all stops' you will see some times in italics. These times are estimated and buses may not arrive at the time stated, they may run slightly earlier or slightly later.</div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};
