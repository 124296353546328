import React, { useEffect, useRef, useState, Fragment } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { useParams, useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import Select from "react-select";
import ReactDatePicker from "react-datepicker";
import { uniqBy } from "lodash";

import ReactGA from 'react-ga';

import "react-datepicker/dist/react-datepicker.css";

import { fetchPrivateCode, fetchStops } from "../redux/actions";
import { getServiceRoutes, getTimetableChanges, getStopsRoutes, getServiceError } from "../redux/selectors";
import { isMobile } from "react-device-detect";

import changeDirectionIcon from "../assets/images/icons/change-direction.svg";
import changeDirectionIconCV from "../assets/images/icons/CV/change-direction.svg";

import changeCalendarIcon from "../assets/images/icons/calendar.svg";
import changeCalendarIconCV from "../assets/images/icons/CV/calendar.svg";

const directionIcon = (opId) => {
  switch (opId) {
    case "CV":
      return changeDirectionIconCV;
    default:
      return changeDirectionIcon;
  }
};

const calendarIcon = (opId) => {
  switch (opId) {
    case "CV":
      return changeCalendarIconCV;
    default:
      return changeCalendarIcon;
  }
};

export function Header() {
  const { control, setValue, getValues } = useForm();

  const [valueFrom, setValueFrom] = useState();
  const selectFrom = useRef();
  const selectTo = useRef();
  const datePicker = useRef();

  const dispatch = useDispatch();
  const { routes, timetableChanges, rawState, errorMessage } = useSelector(
    (state) => ({
      routes: getServiceRoutes(state),
      timetableChanges: getTimetableChanges(getStopsRoutes(state)),
      rawState: state,
      errorMessage: getServiceError(state),
    }),
    shallowEqual
  );

  const { privateCode, date } = useParams();
  const history = useHistory();

  const [mService, setService] = useState();
  const [mOpId, setOpId] = useState();

  const disableDates = (currentDate) =>
    moment(currentDate).isBefore(moment().weekday(0).add(21, "day")) &&
    moment(currentDate).isAfter(moment().weekday(0).subtract(1, "day"));

  const location = useLocation();

  useEffect(() => {
    if (isMobile && datePicker.current)
      datePicker.current.input.readOnly = true;
    if (!routes || (rawState.service && rawState.service.result.departDate !== moment(date).format("YYYY-MM-DD"))) {
      if(privateCode) {
        dispatch(
          fetchPrivateCode(
            JSON.stringify({
              privateCode,
              departDate: moment(date).format("YYYY-MM-DD"),
              reqId: '0'
            })
          )
        );
      }
    }

    if (routes && routes.length) {
      if (!mService && routes[0].service)
        setService(routes[0].service);
      if (!mOpId && routes[0].opId)
        setOpId(routes[0].opId);
      if (mOpId)
        dispatch(
          fetchStops(
            JSON.stringify({
              origin: valueFrom
                ? valueFrom.origin
                : routes[0].origin,
              dest: valueFrom ? valueFrom.dest : routes[0].dest,
              dir: valueFrom ? valueFrom.dir : routes[0].dir,
              departDate: date ? date : moment().format("YYYY-MM-DD"),
              opId: valueFrom ? valueFrom.opId : mOpId,
              service: valueFrom ? valueFrom.service : mService,
              sortBy: "FCTP",
              currentDate : location.search.match('[?&]currentDate=([^&]+)') ? location.search.match('[?&]currentDate=([^&]+)')[1] : null
            })
          )
        );
    }
  }, [routes, mOpId, mService, privateCode, date, valueFrom, dispatch, datePicker, location.search, rawState.service]);

  const exportPDF = (type, date) => {
    const values = getValues();
    ReactGA.event({
      category: 'pdf',
      action: 'weekly-download',
      label: privateCode,
      value: 1
    });
    //_gaq.push(['_trackEvent', 'pdf', 'weekly-download', privateCode, '0']);
    window.open(
      (type === 1 ? "https://bustimetables-pdf.utrackapps.com/generateWeekly.php?origin=" : "https://bustimetables-pdf.utrackapps.com/generate_layover.php?origin=") +
        values.dest.origin +
        "&dest=" +
        values.dest.dest +
        "&privateCode=" +
        privateCode +
        "&departDate=" +
        moment(type === 1 ? date : values.departDate).format("YYYY-MM-DD"),
      "_blank"
    );
  };

  const handleChange = (selectedOption, datePicker) => {
    if (selectedOption && selectFrom.current.state.value.origin !== selectedOption.origin) {
      selectTo.current.select.setValue(selectedOption);
      setValueFrom(selectedOption);
    }
    if (datePicker && !datePicker.action &&
      moment(datePicker).format("YYYY-MM-DD") !==
      moment(date).format("YYYY-MM-DD")
    )
    handleSubmit(selectedOption, datePicker ? moment(datePicker).format("YYYY-MM-DD") : moment(date).format("YYYY-MM-DD"));
  };

  const handleSubmit = ({origin, dest}, departDate) => {
      if (origin && dest && departDate) {
        if (
          moment(departDate).format("YYYY-MM-DD") !==
          moment(date).format("YYYY-MM-DD")
        )
          history.push(
            `${location.pathname.includes('/map/') ? '/map' : ''}/${privateCode}/${moment(departDate).format(
              "YYYY-MM-DD"
            )}`
          );
        /*else
          dispatch(
            fetchStops(
              JSON.stringify({
                origin: valueFrom ? valueFrom.origin : routes[0].origin,
                dest: dest,
                departDate: moment(departDate).format("YYYY-MM-DD"),
                opId: valueFrom ? valueFrom.opId : mOpId,
                service: valueFrom ? valueFrom.service : mService,
                dir: valueFrom ? valueFrom.dir : routes[0].dir,
                sortByDeparture: true,
                currentDate : location.search.match('[?&]currentDate=([^&]+)') ? location.search.match('[?&]currentDate=([^&]+)')[1] : null
              })
            )
          );*/
      }
    };

  const handleSwitch = (selectedOption) => {
    setValueFrom(selectedOption);
    selectTo.current.select.setValue(selectedOption);
  };

  const customStyles = {
    control: (provided, state) => {
      return {
        ...provided,
        background: "#fff",
        borderColor: "#fff",
        minHeight: "43px",
        height: "43px",
        boxShadow: state.isFocused ? null : null,
      };
    },

    valueContainer: (provided) => ({
      ...provided,
      height: "43px",
      padding: "0 13px",
    }),

    input: (provided) => ({
      ...provided,
      margin: "0px",
      color: "#4D4D4D",
      minWidth: "255px",
      width: "97%",
      fontFamily: '"Montserrat", sans-serif',
    }),

    container: (provided) => ({
      ...provided,
      width: "104%",
      minWidth: "255px",
    }),

    singleValue: (provided) => ({
      ...provided,
      fontFamily: '"Montserrat", sans-serif',
      maxWidth: "calc(100% - 23px)",
    }),

    placeholder: (provided) => ({
      ...provided,
      fontFamily: '"Montserrat", sans-serif',
    }),
  };

  let pdfheader = null;
  if (timetableChanges && timetableChanges.length) {
    pdfheader = [];
    for (let i = 0; i< timetableChanges.length; i++) {
      if (!timetableChanges[i]["hol"])
        pdfheader.push(timetableChanges[i]);
    }
  }
  if (routes && routes.length) {
    const vf = selectTo.current ? selectTo.current.state.value : routes[0];
    if(vf && !routes.some(e => e.dest === vf.dest)) {
      if (valueFrom !== routes[0]) {
        setValueFrom(routes[0]);
        selectTo.current.select.setValue(routes[0]);
      }
    }
    if(vf && !routes.some(e => e.origin === vf.origin)) {
      if (valueFrom !== routes[0]) {
        setValueFrom(routes[0]);
        selectTo.current.select.setValue(routes[0]);
      }
    }
  }
  return (
    <Fragment>
      {routes && routes.length ? (
        <Fragment>
          {!errorMessage && pdfheader && pdfheader.length && timetableChanges && timetableChanges.length && !location.pathname.includes('/map/') ?
            <header className={`header header-${mOpId} header-buttons`}>
              {pdfheader.map((timetableChange, i) => {
              return (<div key={i} className={`wrapper-download-pdf wrapper-download-pdf-week ${i !== pdfheader.length - 1 ? 'wrapper-download-pdf-week--first' : ''} wrapper-download-pdf-${mOpId}`}>
                <div>
                  <span>From {(moment(timetableChange["start"]).format('Do MMM YYYY'))}</span>
                </div>
                <button onClick={() => exportPDF(1, moment(timetableChange["start"]))} className="button-download-pdf">
                  <span>Download timetable PDF</span>
                </button>
              </div>)
              })}
            </header> : ''
          }
          {mOpId && <div className={`header header__search header-${mOpId}`}>Search for timetable by date</div>}
          {mOpId && <header className={`header header__main header-${mOpId}`}>
            <div className={`header__stop header__stop__${mService &&  mService.includes('_') ? 'multi' : 'single'} header__stop__${mService &&  mService.length > 9 ? 'small' : 'large'}`}>{mService && mService.replace(/_/g, '/')}</div>
            <form
              className="search-container"
            >
              {routes && routes.length && rawState.service && rawState.service.result && rawState.service.result.routes && (
                <div className="form-container">
                  <div className="form-select">
                    <Controller
                      control={control}
                      name="origin"
                      rules={{ required: true }}
                      defaultValue={routes[0]}
                      render={({ onChange, onBlur, value, name }) => (
                        <Select
                          isSearchable={false}
                          ref={selectFrom}
                          onChange={handleChange}
                          options={uniqBy(routes, "origin")}
                          defaultValue={routes[0]}
                          getOptionLabel={(option) => option.origin}
                          getOptionValue={(option) => option.origin}
                          value={valueFrom}
                          components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                          }}
                          styles={customStyles}
                          placeholder="From"
                          className="react-select-container"
                          classNamePrefix="react-select"
                        />
                      )}
                    />
                  </div>
                  {routes.length > 1 ? (
                    <img
                      src={directionIcon(mOpId)}
                      className="change-direction"
                      alt="change icon"
                      onClick={() => {
                        let { origin } = getValues();
                        origin = valueFrom ? valueFrom : origin;
                        let findroute = null;
                        if (routes.length === 2)
                          findroute = routes.find(
                            (route) => route.dir !== origin.dir
                          );
                        else
                          findroute = routes.find(
                            (route) => route.dest === origin.origin
                          );
                        setValue("dest", { ...origin, dest: origin.origin });
                        if (findroute) {
                          handleSwitch(findroute);
                        }
                      }}
                    />
                  ) : (
                    <div className="change-direction"></div>
                  )}
                  <div className="form-select">
                    <Controller
                      control={control}
                      name="dest"
                      defaultValue={routes[0]}
                      rules={{ required: true }}
                      render={({ onChange, onBlur, value, name }) => (
                        <Select
                          isDisabled={true}
                          ref={selectTo}
                          options={uniqBy(routes, "dest")}
                          value={value}
                          placeholder="To"
                          defaultValue={routes[0]}
                          getOptionLabel={({ origin, dest }) =>
                            selectFrom.current ? selectFrom.current.state.value.dest === selectFrom.current.state.value.origin ? "Circular" : dest
                                               : dest === origin ? "Circular" : dest
                          }
                          getOptionValue={({ dest }) => dest}
                          components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                          }}
                          styles={customStyles}
                          className="react-select-container"
                          classNamePrefix="react-select"
                        />
                      )}
                    />
                  </div>
                  <div className={`${location.pathname.includes('/map/') ? 'map-hidden' : ''}`}>
                  <div className='date-picker'>
                    <Controller
                      control={control}
                      name="departDate"
                      rules={{ required: true }}
                      defaultValue={date ? moment(date).toDate() : new Date()}
                      render={(props) => (
                        <ReactDatePicker
                          minDate={moment().weekday(0).add(21, "day")}
                          filterDate={disableDates}
                          ref={datePicker}
                          className="input"
                          placeholderText="Select date"
                          onChange={(e) => {props.onChange(e);handleChange(valueFrom ? valueFrom : routes[0], e)}}
                          selected={props.value}
                          dateFormat="do MMM yyyy"
                        />
                      )}
                    />
                    <img
                      src={calendarIcon(mOpId)}
                      className="change-date"
                      alt="date icon"
                      onClick={() => {
                        datePicker.current.setOpen(true);
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </form>
        </header>}
      </Fragment>
        ) : (rawState.service && rawState.service.result && !rawState.service.result.routes &&
          <div className="no-timetable-header">
            <div className="no-timetable">No Timetable Available</div>
          </div>
        )}
    </Fragment>
  );
}
