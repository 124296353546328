import { RECEIVE_STOPS } from "../actionTypes";

export default function (state = {}, action) {
  switch (action.type) {
    case RECEIVE_STOPS:
      return {
        ...state.stops,
        ...action.payload,
      };
    default:
      return state;
  }
}
