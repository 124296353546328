import { RECEIVE_SERVICE, RECEIVE_STOPS, RECEIVE_POLYLINE, RESET_VIEW } from "./actionTypes";
import fetch from "cross-fetch";

function receiveService(json) {
  return {
    type: RECEIVE_SERVICE,
    payload: json,
  };
}

function receiveStops(json) {
  return {
    type: RECEIVE_STOPS,
    payload: json,
  };
}

function receivePolyline(json) {
  return {
    type: RECEIVE_POLYLINE,
    payload: json,
  };
}

export function fetchPrivateCode(body) {
  return (dispatch) => {
    return fetch(
      "https://nxbus-embed-api.utrackapps.com/tts-nxbus/api/route/getByPrivateCode",
      {
        body,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
      }
    )
      .then((response) => response.json())
      .then((json) => dispatch(receiveService(json)));
  };
}

export function fetchStops(body) {
  return (dispatch) => {
    return fetch(
      "https://nxbus-embed-api.utrackapps.com/tts-nxbus/api/timetable/getByRoute",
      {
        body,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
      }
    )
      .then((response) => response.json())
      .then((json) => dispatch(receiveStops(json)));
  };
}

export function fetchPolyline(body) {
  return (dispatch) => {
    return fetch(
      "https://nxbus-embed-api.utrackapps.com/tts-nxbus/api/polyline/getByMaster",
      {
        body,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
      }
    )
      .then((response) => response.json())
      .then((json) => dispatch(receivePolyline(json)));
  };
}

export function resetView(value) {
  return {
    type: RESET_VIEW,
    payload: value,
  };
}
